import {Stack} from "@mui/material";
import {Button} from "@komune-io/g2-components";
import {DeleteButton} from "../DeleteButton/DeleteButton";
import {FormComposableState} from "@komune-io/g2";
import {useTranslation} from "react-i18next";

export interface PopupFormActionProps {
  readonly?: boolean;
  isCreation: boolean
  feedback: boolean | undefined;
  onCancel?: () => void
  onDelete?: () => void
  formState: FormComposableState
}

export const PopupFormAction = (props: PopupFormActionProps) => {
  const {readonly = false,isCreation, feedback, formState, onCancel, onDelete} = props
  const {t} = useTranslation()
  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      width='100%'
    >
      {!readonly && (
        <Stack direction="row" gap={1}>
          <Button
            onClick={onCancel}
            variant="text"
          >
            Annuler
          </Button>
          <Button
            success={feedback !== undefined && feedback}
            fail={feedback !== undefined && !feedback}
            onClick={formState.submitForm}
          >
            {isCreation ? t("create") : t("update")}
          </Button>
          {onDelete && <DeleteButton onClick={onDelete}/>}
        </Stack>
      )}
    </Stack>
  )
};
