import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Row} from "@komune-io/g2-layout";
import {SubOrganizationButton} from "./SubOrganizationButton";
import {SubOrganization} from "../../../model";
import {SubOrganizationTable} from "../table/SubOrganizationTable";
import {toPage} from "@tracabois/utils";
import {PlatformSubSection} from "@tracabois/template";

interface SubOrganizationSectionProps {
  organizationId: string
  subOrganizations: SubOrganization[]
  refreshData: () => void
  isLoading?: boolean
}

export const SubOrganizationSection = (props: SubOrganizationSectionProps) => {
  const {organizationId, subOrganizations, isLoading, refreshData} = props
  const page = toPage(subOrganizations)
  const [selectedItem, setSelectedItem] = useState<Partial<SubOrganization> | undefined>(undefined)
  const {t} = useTranslation()
  const clickHandler = (item: Row<SubOrganization>) => {
    setSelectedItem(item.original)
  }
  const actions = (organizationId && <SubOrganizationButton
      organizationId={organizationId}
      onSubOrganizationCreated={refreshData}
      onSubOrganizationUpdated={refreshData}
      item={selectedItem}
      label={t("removalZone.action.create", "Ajouter un établissement")}
  />)
  return (
    <PlatformSubSection title={"Établissement"}>
      <SubOrganizationTable
        page={page}
        onPageQueryChange={() => {
        }}
        onRowClicked={clickHandler}
        isLoading={isLoading}
        actions={actions}
      />
    </PlatformSubSection>)
}
